import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './Header.module.css';
import Logo from '../../assets/fantastick_logo.png';
import LogoFont from '../../assets/logo_font.png';

class Header extends Component {
  render(){
    return(
      <div className={styles.Header} data-testid="Header">
        <div className={styles.logoBox}>
          <img className={styles.logo} src={Logo}/>
        </div>
        <div className={styles.titleBox}>
          <img className={styles.title} data-testid="Title" src={LogoFont}/>
          <div className={styles.subTitle} data-testid="SubTitle">Custom American Made Snowboards</div>
        </div>
      </div>
    )
  }
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
