import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './Boards.module.css';

class Boards extends Component{
  constructor() {
    super();
      this.state = {
      showOrderForm: false
    };
  }

  render(){
    return(
      <>
        <div className={styles.Boards} data-testid="Boards">
        <h1>Build Tiers and Pricing Explained</h1>
          <div>
            <div className={styles.tierList}>
              <div className={styles.tierTitle}>Standard Shapes $800 +/-</div>
              <ul className={styles.tierDescription}>
                <li>
                  Solid base sheet, Sintered 4001 grade UHMW. Black will be typical, if other colors are available, I will advise at time of order
                </li>
                <li>
                  Solid ABS side walls. Black will be typical, if other colors are available, I will advise at time of order
                </li>
                <li>
                  Solid Nylon top sheet. White will be typical, if other colors are available, I will advise at time of order
                </li>
              </ul>
            </div>
            <div className={styles.tierList}>
              <div className={styles.tierTitle}>Standard Plus Shapes $1300 +/-</div>
              <ul className={styles.tierDescription}>
                <li>
                  Solid base sheet, Sintered 4001 grade UHMW. Black will be typical, if other colors are available, I will advise at time of order
                </li>
                <li>
                  Solid ABS side walls. Black will be typical, if other colors are available, I will advise at time of order
                </li>
                <li>
                  Your choice of hardwood veneer top sheet. I will email available grain options and wood types to choose from (cost will vary per wood type)
                </li>
                <li>
                  Custom color or pattern at tail (cost will vary on final pattern, if any)
                </li>
                <li>
                  6 sprayed coats of UV protective, water resistant, clear polyurethane. Gloss and Satin finishes are available
                </li>
                <li>
                  The wood veneer gives a damper feel under foot. This helps with the shape's ability to absorb hits in choppy snow, chunder, and ruts left after the powder hounds leave the mountain at 10:30 on pow day
                </li>
              </ul>
            </div>
            <div className={styles.tierList}>
              <div className={styles.tierTitle}>Custom Shapes $1600 +</div>
              <ul className={styles.tierDescription}>
                <li>
                  Solid base sheet, Sintered 4001 grade UHMW. Black will be typical, if other colors are available, I will advise at time of order
                </li>
                <li>
                  Standard nose and tail configuration or choice of your own unique shape
                </li>
                <li>
                  Hardwood side walls, your choice of different wood types such as Purple Heart, Bubinga, Mahogany, etc.
                </li>
                <li>
                  Your choice of hardwood veneer top sheet. I will email grain options and wood types to choose from (cost will vary per wood type)
                </li>
                <li>
                  Clear top sheet if you opt for a core with multiple wood types
                </li>
                <li>
                  Custom color or pattern at tail (cost will vary on final pattern, if any)
                </li>
                <li>
                  6 sprayed coats of UV protective, water resistant, clear polyurethane. Gloss and Satin finishes are available
                </li>
                <li>
                  Truly a work of art; mid-century inspired shapes and finish that you will want to display year-round
                </li>
                <li>
                  “Custom Shapes” will have a 100% unique feel under foot. If you have never ridden a full “Woody” it needs to be added to your snowboard bucket list!
                </li>
              </ul>
            </div>
            <h1>Baseline Specs</h1>
            <div className={styles.tierList}>
              <ul className={styles.tierDescription}>
                <li>
                  Custom tuned poplar core
                </li>
                <li>
                  Final length and width will be figured pending rider's specs and riding preferences         
                </li>
                <li>
                  Nose width 32cm
                </li>
                <li>
                  Waist width 27cm
                </li>
                <li>
                  Tail width 29 cm
                </li>
                <li>
                  Effective edge 1200mm
                </li>
                <li>
                  Side cut radius 8.2m
                </li>
                <li>
                  Camber under foot 7mm
                </li>
                <li>
                  Flex rating 8.5/10, but has very fast edge to edge transitions
                </li>
                <li>
                  Weight +/- 7.2-8 lbs depending on your specs and final shape
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    )
  }
};

Boards.propTypes = {};

Boards.defaultProps = {};

export default Boards;
