import React, {useState} from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styles from './Order.module.css';
import Board1 from '../../assets/nose_1.jpg';
import Board2 from '../../assets/nose_2.jpg';
import Board3 from '../../assets/nose_3.jpg';
import Board4 from '../../assets/nose_4.jpg';
import Board5 from '../../assets/nose_5.jpg';
import Board6 from '../../assets/nose_6.jpg';
import Nose1 from '../../assets/nose_shape_1.JPG';
import Nose2 from '../../assets/nose_shape_2.JPG';
import Nose3 from '../../assets/nose_shape_3.JPG';
import Nose4 from '../../assets/nose_shape_4.JPG';
import Nose5 from '../../assets/nose_shape_5.JPG';
import Nose6 from '../../assets/nose_shape_6.JPG';
import Tail1 from '../../assets/tail_shape_1.JPG';
import Tail2 from '../../assets/tail_shape_2.JPG';
import Tail3 from '../../assets/tail_shape_3.JPG';
import Tail4 from '../../assets/tail_shape_4.JPG';
import Tail5 from '../../assets/tail_shape_5.JPG';
import Tail6 from '../../assets/tail_shape_6.JPG';
import Tail7 from '../../assets/tail_shape_7.JPG';
import RotateLoader from "react-spinners/RotateLoader";

function Order() {
  const [nose, setNose] = useState();
  const [tail, setTail] = useState();
  const [tier, setTier] = useState();
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [bootSize, setbootSize] = useState();
  const [height, setHeight] = useState();
  const [weight, setWeight] = useState();
  const [stance, setStance] = useState();
  const [stanceWidth, setStanceWidth] = useState();
  const [stanceAngle, setStanceAngle] = useState();
  const [ridingStyle, setRidingStyle] = useState();
  const [boardDesign, setBoardDesign] = useState();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false)

  const selectNose = (noseSelection) => {
    if (nose === noseSelection){
      setNose();
    }else{
      setNose(noseSelection);
    };
  }

  const selectTail = (tailSelection) => {
    if (tail === tailSelection){
      setTail();
    }else{
      setTail(tailSelection);
    };
  }

  const buildOrder = {
    nose: nose,
    tail: tail,
    tier: parseInt(tier),
    email: email,
    name: name,
    boot_size: parseFloat(bootSize),
    height: parseFloat(height),
    weight: parseFloat(weight),
    stance: parseInt(stance),
    stance_width: parseFloat(stanceWidth),
    stance_angle: stanceAngle,
    riding_style: ridingStyle,
    board_design: boardDesign
  };

  const validateForm = () => {
    console.log(process.env.NODE_ENV)
    const newErrors = {};
    if (!nose) newErrors.nose = 'Please select a nose';
    if (!tail) newErrors.tail = 'Please select a tail';
    if (!tier) newErrors.tier = 'Tier is required.';
    if (!email) newErrors.email = 'Email is required.';
    if (!name) newErrors.name = 'Name is required.';
    if (!bootSize) newErrors.bootSize = 'Boot Size is required.';
    if (!height) newErrors.height = 'Height is required.';
    if (!weight) newErrors.weight = 'Weight is required.';
    if (!stance) newErrors.stance = 'Stance is required.';
    if (!stanceWidth) newErrors.stanceWidth = 'Stance Width is required.';
    if (!stanceAngle) newErrors.stanceAngle = 'Stance Angles are required.';
    if (!ridingStyle) newErrors.ridingStyle = 'Riding Style is required.';
    if (!boardDesign) newErrors.boardDesign = 'Board Design is required.';
    return newErrors;
  };

  const submitOrder = () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      alert('Please fill out all fields on the build form.');
    } else {
      setLoading(true);
      // Handle form submission

      axios.post("https://fantastickshapesapi-a078a7ce0ae5.herokuapp.com/orders", buildOrder).then((response) => {
        if (response.status === 201) {
          alert('Thank you for submitting your build request! You will be contacted to confirm your custom shape and payment details.');
          setLoading(false);
          window.location.reload(true);
          setErrors('');
        } else {
          console.log(response.error);
          alert('Sorry there was a problem placing your order, please try again later!');
        };
      });
    }
  };

  const boardSelection = [{nose: 1, image: Board1}, {nose: 2, image: Board2}, {nose: 3, image: Board3}, {nose: 4, image: Board4}, {nose:5, image: Board5}, {nose: 6, image: Board6}]
  const noseSelection = [{nose: 1, image: Nose1}, {nose: 2, image: Nose2}, {nose: 3, image: Nose3}, {nose: 4, image: Nose4}, {nose:5, image: Nose5}, {nose: 6, image: Nose6}]
  const tailSelection = [{tail: 1, image: Tail1}, {tail: 2, image: Tail2}, {tail: 3, image: Tail3}, {tail: 4, image: Tail4}, {tail:5, image: Tail5}, {tail: 6, image: Tail6}, {tail: 7, image: Tail7}]


  return (
    <div className={styles.orderForm}>
      <h1>Board Configurator</h1>
      <div className={styles.imageContainer}>
        <div>All nose and tail combinations:</div>
        <div className={styles.boardSection} >
          {boardSelection.map(board => <img className={styles.boardImage} src={board.image}/>)}
        </div>
      </div>
      <div className={styles.imageContainer}>
        <div>Select your nose shape:</div>
        <div className={styles.boardSection}>
          {noseSelection.map(board => (
            <div style={{display: 'flex', flexDirection: 'column'}}>
            <div className={`${nose === board.nose ? styles.selected : styles.imageTile}`}>
              <img
                key={board.nose}
                onClick={() => selectNose(board.nose)}
                className={styles.tailImage}
                src={board.image}
                alt={board.nose} />
            </div>
            </div>
          ))}
        </div>
      </div>
      {errors.nose && !nose && <small className="text-danger">{errors.nose}</small>}
      <div className={styles.imageContainer}>
        <div>Select your tail shape:</div>
        <div className={styles.boardSection}>
          {tailSelection.map(board => (
            <>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <div className={`${tail === board.tail ? styles.selected : styles.imageTile}`}>
                  <img
                    key={board.tail}
                    onClick={() => selectTail(board.tail)}
                    className={styles.tailImage}
                    src={board.image}
                    alt={board.tail} />
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      {errors.tail && !tail && <small className="text-danger">{errors.tail}</small>}
      <Form style={{width: '90%', display: 'flex', flexDirection: 'column'}}>
        <Form.Group controlId="tierSelect">
          <Form.Label style={{ margin: '.5rem', display: 'block', textAlign: 'left' }}>Select a Tier:</Form.Label>
          <Form.Control style={{backgroundColor: 'lightgreen'}} as="select" onChange={e => setTier(e.target.value)}>
            <option value="">--Please choose an option--</option>
            <option value={1}>Standard Shape - $800</option>
            <option value={2}>Standard Plus Shape - $1300+</option>
            <option value={3}>Custom Shape - $1600+</option>
          </Form.Control>
          {errors.tier && !tier && <small className="text-danger">{errors.tier}</small>}
        </Form.Group>

        <Form.Group controlId='email'>
          <Form.Label style={{ margin: '.5rem', display: 'block', textAlign: 'left' }}>Email:</Form.Label>
          <Form.Control 
            type='email' 
            placeholder="Enter Email" 
            value={email} 
            onChange={e => setEmail(e.target.value)}
            style={{backgroundColor: 'lightgreen'}} 
          />
          {errors.email && !email && <small className="text-danger">{errors.email}</small>}
        </Form.Group>

        <Form.Group controlId='name'>
          <Form.Label style={{ margin: '.5rem', display: 'block', textAlign: 'left' }}>Name:</Form.Label>
          <Form.Control 
            type='text'
            placeholder="Enter Name"
            value={name}
            onChange={e => setName(e.target.value)}
            style={{backgroundColor: 'lightgreen'}}
          />
          {errors.name && !name && <small className="text-danger">{errors.name}</small>}
        </Form.Group>

        <Form.Group controlId='bootSize'>
          <Form.Label style={{ margin: '.5rem', display: 'block', textAlign: 'left' }}>Boot Size:</Form.Label>
          <Form.Control 
            type='text' 
            placeholder="Enter Boot Size" 
            value={bootSize} 
            onChange={e => setbootSize(e.target.value)}
            style={{backgroundColor: 'lightgreen'}}
          />
          {errors.bootSize && !bootSize && <small className="text-danger">{errors.bootSize}</small>}
        </Form.Group>

        <Form.Group controlId='height'>
          <Form.Label style={{ margin: '.5rem', display: 'block', textAlign: 'left' }}>Height:</Form.Label>
          <Form.Control 
            type='text' 
            placeholder="Enter Height (Inches)" 
            value={height} 
            onChange={e => setHeight(e.target.value)}
            style={{backgroundColor: 'lightgreen'}} 
          />
          {errors.height && !height && <small className="text-danger">{errors.height}</small>}
        </Form.Group>

        <Form.Group controlId='weight'>
          <Form.Label style={{ margin: '.5rem', display: 'block', textAlign: 'left' }}>Weight (with gear):</Form.Label>
          <Form.Control 
            type='text' 
            placeholder="Enter Weight (Pounds)" 
            value={weight} 
            onChange={e => setWeight(e.target.value)}
            style={{backgroundColor: 'lightgreen'}} 
          />
          {errors.weight && !weight && <small className="text-danger">{errors.weight}</small>}
        </Form.Group>

        <Form.Group controlId="stanceSelect">
          <Form.Label style={{ margin: '.5rem', display: 'block', textAlign: 'left' }}>Stance:</Form.Label>
          <Form.Control style={{backgroundColor: 'lightgreen'}} as="select" onChange={e => setStance(e.target.value)}>
            <option value="">--Please choose an option--</option>
            <option value={1}>Regular</option>
            <option value={2}>Goofy</option>
          </Form.Control>
          {errors.stance && !stance && <small className="text-danger">{errors.stance}</small>}
        </Form.Group>

        <Form.Group controlId='stanceWidth'>
          <Form.Label style={{ margin: '.5rem', display: 'block', textAlign: 'left' }}>Stance Width:</Form.Label>
          <Form.Control 
            type='text' 
            placeholder="Enter Width (Inches)" 
            value={stanceWidth} 
            onChange={e => setStanceWidth(e.target.value)}
            style={{backgroundColor: 'lightgreen'}} 
          />
          {errors.stanceWidth && !stanceWidth && <small className="text-danger">{errors.stanceWidth}</small>}
        </Form.Group>

        <Form.Group controlId='stanceAngles'>
          <Form.Label style={{ margin: '.5rem', display: 'block', textAlign: 'left' }}>Stance Angles (Front/Rear):</Form.Label>
          <Form.Control 
            type='text' 
            placeholder="Enter Angles" 
            value={stanceAngle} 
            onChange={e => setStanceAngle(e.target.value)} 
            style={{backgroundColor: 'lightgreen', paddingBottom: '15px'}}
          />
          {errors.stanceAngle && !stanceAngle && <small className="text-danger">{errors.stanceAngle}</small>}
        </Form.Group>

        <Form.Group controlId='ridingStyle'>
          <Form.Label style={{ margin: '.5rem', display: 'block', textAlign: 'left' }}>Riding Style:</Form.Label>
          <Form.Control 
            as="textarea" 
            rows={3} 
            placeholder="Describe your riding style and where you like to ride, such as hard trenching carves, slashy carves, skidded turns, piste, off-piste, powder, backcountry, feel under feet (locked in, surfy, or happy medium)"
            value={ridingStyle} 
            onChange={e => setRidingStyle(e.target.value)}
            style={{backgroundColor: 'lightgreen', paddingBottom: '15px'}} 
          />
          {errors.ridingStyle && !ridingStyle && <small className="text-danger">{errors.ridingStyle}</small>}
        </Form.Group>

        <Form.Group controlId='boardDesign'>
          <Form.Label style={{ margin: '.5rem', display: 'block', textAlign: 'left' }}>Board Design:</Form.Label>
          <Form.Control 
            as="textarea" 
            rows={3} 
            placeholder="Describe how you would like your board to look: Wood/ABS topsheet and sidewalls, Base ABS Color, Tail Design and Board Size  (what is your desired board size, final size may vary slightly based on the final shape selected). Final designs will be confirmed before production begins."
            value={boardDesign} 
            onChange={e => setBoardDesign(e.target.value)}
            style={{backgroundColor: 'lightgreen', paddingBottom: '15px'}} 
          />
          {errors.boardDesign && !boardDesign && <small className="text-danger">{errors.boardDesign}</small>}
        </Form.Group>

        {!loading && (
          <div className={styles.orderButton}>
            <Button style={{backgroundColor: 'darkgreen'}} variant='primary' onClick={submitOrder}>Submit Build Request</Button>
          </div>
        )}
      </Form>
      <div className={styles.loading}>
        <RotateLoader
          color={'white'}
          loading={loading}
          size={15}
          margin={2}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </div>
  );
}

export default Order;
