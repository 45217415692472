import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './About.module.css';
import ReactPlayer from 'react-player'
import video from '../../assets/how_to_order.MP4'

class About extends React.Component {
  constructor() {
    super();
      this.state = {
      playing: false
    };
  }

  render(){
    return(
    <div className={styles.About} data-testid="About">
      <h1>About</h1>  
      <div className={styles.features}>
        <p className={styles.description}>
          Fantastick Shapes was born out of a deep curiosity in snowboard materiality, especially distinctive wood topsheets. This curiosity led to a quest to push the boundaries of functionality and design with carving-focused, unique snow-shapes. Through my journey, I discovered the incredible difference it makes when a snowboard is tailored, or fitted, to a specific rider. Since no two people are exactly the same, why should their snowboards be? We believe that having a snowboard built to your personal specifications provides better response, improved feel underfoot, and enhanced stability compared to industry-standard sizing. I'm always seeking the best possible ride and the ultimate stoke on the mountain—let us help you find yours! 
          Every board that leaves our shop includes:
        </p>
        <ul className={styles.featureList}>
          <li>
            Full camber underfoot with early-rise rocker in the nose
          </li>
          <li>
            Book-matched poplar core, custom-tuned for each rider
          </li>
          <li>
            Heavy-duty felt board bag included
          </li>
          <li>
            Detail-focused, handcrafted shapes made from locally sourced materials that support small businesses and distributors
          </li>
          <li>
            Custom-crafted wooden wall mounts that show your love for the snowboard's design and the ride
          </li>
          <li>
            Swallowtails will allow for a more playful or slashy feel, especially if you want to throw a speed check in as you are exiting a carve
          </li>
          <li>
            Pintails will allow for a bit harder charging and locked in feel when exiting your carve
          </li>
          <li>
            All shapes are very stable underfoot, both at slow and high speeds 
          </li>
          <li>
            All shapes can float magically in deep pow and transition to super carvers on fresh piste
          </li>
          <li>
            Committed to sustainable materials and practices whenever possible for a niche small business:
            <a className={styles.link} href='https://protectourwinters.org/'>
              Protect Our Winters!
            </a>
          </li>
        </ul>
      </div>
      <div>
        <iframe className={styles.videoFrame} src="https://www.youtube.com/embed/Lvrxzu2du-A?si=gSPCKkeGaYPZXzBM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
    </div>
    )
  }
};

About.propTypes = {};

About.defaultProps = {};

export default About;
