// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Warranty_Warranty__qFYPJ {
    background-color: seagreen;
    padding: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.Warranty_warrantyContainer__O9-ZU {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
}

.Warranty_warrantyDescription__y\\+VD5 {
    display: flex;
    flex-direction: column;
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/Warranty/Warranty.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB","sourcesContent":[".Warranty {\n    background-color: seagreen;\n    padding: 24px;\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n}\n\n.warrantyContainer {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    width: 90%;\n}\n\n.warrantyDescription {\n    display: flex;\n    flex-direction: column;\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Warranty": `Warranty_Warranty__qFYPJ`,
	"warrantyContainer": `Warranty_warrantyContainer__O9-ZU`,
	"warrantyDescription": `Warranty_warrantyDescription__y+VD5`
};
export default ___CSS_LOADER_EXPORT___;
