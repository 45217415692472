import React from 'react';
import { FaInstagram } from 'react-icons/fa'; // Import the Instagram icon

const InstagramLink = () => {
  return (
    <a
      href="https://www.instagram.com/fantastickshapessnowboards/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none', color: 'white' }} // Optional styling
    >
      <FaInstagram size={40} />
    </a>
  );
};

export default InstagramLink;