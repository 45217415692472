// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.About_About__LeFbV {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f7f7f7;
    padding: 24px;
}

.About_description__\\+dvZe {
    text-align: left;
}

.About_features__-j9Js {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
    font-size: 18px;
    font-weight: 400;
}

.About_featureList__otviY {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.About_videoFrame__y15hd {
    height: 30vh;
    width: 70vw;
    color: white;
}

.About_video__74smB {
    height: 100%;
    width: 100%;
}

.About_link__6TTmW{
    padding-left: 3px;
}

@media (min-width: 580px) {
    .About_videoFrame__y15hd {
        height: 70vh;
        width: 70vw;
        color: white;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/About/About.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI;QACI,YAAY;QACZ,WAAW;QACX,YAAY;IAChB;AACJ","sourcesContent":[".About {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    background-color: #f7f7f7;\n    padding: 24px;\n}\n\n.description {\n    text-align: left;\n}\n\n.features {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 70vw;\n    font-size: 18px;\n    font-weight: 400;\n}\n\n.featureList {\n    display: flex;\n    flex-direction: column;\n    text-align: left;\n}\n\n.videoFrame {\n    height: 30vh;\n    width: 70vw;\n    color: white;\n}\n\n.video {\n    height: 100%;\n    width: 100%;\n}\n\n.link{\n    padding-left: 3px;\n}\n\n@media (min-width: 580px) {\n    .videoFrame {\n        height: 70vh;\n        width: 70vw;\n        color: white;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"About": `About_About__LeFbV`,
	"description": `About_description__+dvZe`,
	"features": `About_features__-j9Js`,
	"featureList": `About_featureList__otviY`,
	"videoFrame": `About_videoFrame__y15hd`,
	"video": `About_video__74smB`,
	"link": `About_link__6TTmW`
};
export default ___CSS_LOADER_EXPORT___;
