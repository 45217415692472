import React from 'react';
import PropTypes from 'prop-types';
import styles from './Warranty.module.css';

const Warranty = () => (
  <div className={styles.Warranty} data-testid="Warranty">
    <h1>Board Care</h1>
    <div className={styles.warrantyContainer}>
      <ul className={styles.warrantyDescription}>
        <li>
          Wood veneers and wood side walls, although a magical ride, are more susceptible to cosmetic imperfections
        </li>
        <li>
          They will require a bit more attention to the shapes’ finish and your surroundings
        </li>
        <li>
          The person behind you ramming rental skis into your investment will leave a mark
        </li>
        <li>
          The person who cannot sit still on the lift and slams his board into your top sheet will probably leave a mark
        </li>
        <li>
          Dragging your shape behind you in the parking lot while walking back to the car will leave a gouge
        </li>
        <li>
          Throwing your shape, unprotected, into the back of your car after a long day on the mountain and having your partners’ board bang against it for the 4-hour drive home will most likely mar the finish
        </li>
        <li>
          Most of these mishaps can be repaired, but unfortunately NOT for free
        </li>
        <li>
          Truly a work of art; mid-century inspired shapes and finish that you will want to display year-round
        </li>
        <li>
          All shapes will be beautiful objects, well crafted snowboards that will give you a “Fantastick” day on the mountain
        </li>
      </ul>
    </div>
    <h1>Warranty</h1>
    <div className={styles.warrantyContainer}>
      <ul className={styles.warrantyDescription}>
        <li>
          All shapes have a 1-year warranty on manufacturing defects. Please note, hitting a tree, a rock, a jib, casing a jump (or other) and damaging your board is not considered a manufacturing defect. If a board needs to be repaired from rider damage we can be contacted for an initial quote
        </li>
      </ul>
    </div>
  </div>
);

Warranty.propTypes = {};

Warranty.defaultProps = {};

export default Warranty;
