import React from 'react';
import PropTypes from 'prop-types';
import styles from './Contact.module.css';
import InstagramLink from '../InstagramLink/InstagramLink';

const Contact = () => (
  <div className={styles.Contact} data-testid="Contact">
    <h1>Contact Info</h1>
    <div>For any questions or comments about boards please contact fantastickshapes@gmail.com</div>
    <div>This website is a beta launch. To report any issues, provide feedback or give suggestions please contact fantastickshapeswebsite@gmail.com</div>
    <div>Paypal/Venmo integration and Accounts coming soon!</div>
    <InstagramLink />
  </div>
);

Contact.propTypes = {};

Contact.defaultProps = {};

export default Contact;
