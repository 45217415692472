import './App.css';
import About from './components/About/About';
import Boards from './components/Boards/Boards';
import Header from './components/Header/Header';
import Images from './components/Images/Images';
import Order from './components/Order/Order';
import Warranty from './components/Warranty/Warranty';
import Contact from './components/Contact/Contact';

function App() {
  return (
    <div className="App">
      <Header />
      <Images />
      <About />
      <Boards />
      <Order />
      <Warranty />
      <Contact />
    </div>
  );
}

export default App;
