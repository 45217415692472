// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Contact_Contact__Es1ST {
    color: white;
    background-color: black;
    padding: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/Contact/Contact.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,uBAAuB;IACvB,aAAa;AACjB","sourcesContent":[".Contact {\n    color: white;\n    background-color: black;\n    padding: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Contact": `Contact_Contact__Es1ST`
};
export default ___CSS_LOADER_EXPORT___;
