// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/COPASETI.TTF", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Copasetic';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: normal;
    font-style: normal;
}

.Header_Header__Z9Z4k {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #f4a127;
    padding: 24px;
    justify-content: space-between;
}

.Header_title__x3ga3 {
    width: 35vw;
    height: auto;
}

.Header_titleBox__f2jbb{
    padding-right: 24px;
}

.Header_subTitle__IzeTk {
    color: black;
    line-height: 24px;
    font-size: 20px;
    font-weight: 400;
}

.Header_logoBox__16exS, .Header_centeringDiv__bN7ci {
    display: flex;
    justify-content: flex-start;
    flex: 1 1;
    padding: 5px;
}

.Header_logo__UsziS {
    max-width: 15vh;
    height: auto;
    padding-left: 24px;
}


@media (min-width: 580px) {
    .Header_logo__UsziS {
        max-width: 30vh;
        height: auto;
        padding-left: 24px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,+DAAwD;IACxD,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,SAAO;IACP,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,kBAAkB;AACtB;;;AAGA;IACI;QACI,eAAe;QACf,YAAY;QACZ,kBAAkB;IACtB;AACJ","sourcesContent":["@font-face {\n    font-family: 'Copasetic';\n    src: url('../../assets/COPASETI.TTF') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n}\n\n.Header {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    background-color: #f4a127;\n    padding: 24px;\n    justify-content: space-between;\n}\n\n.title {\n    width: 35vw;\n    height: auto;\n}\n\n.titleBox{\n    padding-right: 24px;\n}\n\n.subTitle {\n    color: black;\n    line-height: 24px;\n    font-size: 20px;\n    font-weight: 400;\n}\n\n.logoBox, .centeringDiv {\n    display: flex;\n    justify-content: flex-start;\n    flex: 1;\n    padding: 5px;\n}\n\n.logo {\n    max-width: 15vh;\n    height: auto;\n    padding-left: 24px;\n}\n\n\n@media (min-width: 580px) {\n    .logo {\n        max-width: 30vh;\n        height: auto;\n        padding-left: 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header": `Header_Header__Z9Z4k`,
	"title": `Header_title__x3ga3`,
	"titleBox": `Header_titleBox__f2jbb`,
	"subTitle": `Header_subTitle__IzeTk`,
	"logoBox": `Header_logoBox__16exS`,
	"centeringDiv": `Header_centeringDiv__bN7ci`,
	"logo": `Header_logo__UsziS`
};
export default ___CSS_LOADER_EXPORT___;
