import React from 'react';
import PropTypes from 'prop-types';
import styles from './Images.module.css';
import Tail1 from '../../assets/tail_1.jpeg'
import Tail2 from '../../assets/tail_2.jpeg'
import Tail3 from '../../assets/tail_3.jpeg'
import Tail4 from '../../assets/tail_4.jpeg'
import Tail5 from '../../assets/tail_5.jpeg'
import Tail6 from '../../assets/tail_6.jpeg'
import Portfolio1 from '../../assets/portfolio1.JPG'
import Portfolio2 from '../../assets/portfolio2.JPG'
import Portfolio3 from '../../assets/portfolio3.JPG'
import Portfolio4 from '../../assets/portfolio4.JPG'
import Portfolio5 from '../../assets/portfolio5.JPG'
import Portfolio6 from '../../assets/portfolio6.JPG'
import Portfolio7 from '../../assets/portfolio7.JPG'
import Portfolio8 from '../../assets/portfolio8.JPG'
import Portfolio9 from '../../assets/portfolio9.JPG'
import Portfolio10 from '../../assets/portfolio10.JPG'
import Portfolio11 from '../../assets/portfolio11.jpeg'

const imageArray = [Portfolio1, Portfolio2, Portfolio3, Portfolio4, Portfolio5, Portfolio6, Portfolio7, Portfolio8, Portfolio9, Portfolio10, Portfolio11]

class Images extends React.Component {
  constructor() {
    super();
      this.state = {
      playing: false
    };
  }
  render(){
    return(
      <div className={styles.Images} data-testid="Images">
        <div className={styles.imageGrid}>
          <div className={styles.imageRow}>
            <img className={styles.image} src={Tail1}/>
            <img className={styles.image} src={Tail2}/>
            <img className={styles.image} src={Tail3}/>
          </div>
          <div className={styles.imageRow}>
            <img className={styles.image} src={Tail4}/>
            <img className={styles.image} src={Tail5}/>
            <img className={styles.image} src={Tail6}/>
          </div>
          <div className={styles.portfolio}>
            {imageArray.map(image => <img className={styles.portfolioImage} src={image} />)}
          </div>
        </div>
    </div>
    )
  }
};

Images.propTypes = {};

Images.defaultProps = {};

export default Images;
