// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Images_imageGrid__l8NpR {
    background-color:	#ffecb4;
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 24px;
    gap: 10px;
}

.Images_imageRow__L1I6H {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px
}

.Images_image__32yed {
    max-width: 30%;
    height: auto;
    width: auto;
    border-radius: 10px;
}

.Images_portfolio__aqoTg {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
}

.Images_portfolioImage__2xeKc {
    max-width: 25vw;
    max-height: auto;
    border-radius: 10px;
    margin: 6px;
 }`, "",{"version":3,"sources":["webpack://./src/components/Images/Images.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB;AACJ;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,WAAW;CACd","sourcesContent":[".imageGrid {\n    background-color:\t#ffecb4;\n    display: flex;\n    flex-direction: column;\n    height: auto;\n    padding: 24px;\n    gap: 10px;\n}\n\n.imageRow {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    gap: 10px\n}\n\n.image {\n    max-width: 30%;\n    height: auto;\n    width: auto;\n    border-radius: 10px;\n}\n\n.portfolio {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    overflow-x: scroll;\n}\n\n.portfolioImage {\n    max-width: 25vw;\n    max-height: auto;\n    border-radius: 10px;\n    margin: 6px;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageGrid": `Images_imageGrid__l8NpR`,
	"imageRow": `Images_imageRow__L1I6H`,
	"image": `Images_image__32yed`,
	"portfolio": `Images_portfolio__aqoTg`,
	"portfolioImage": `Images_portfolioImage__2xeKc`
};
export default ___CSS_LOADER_EXPORT___;
