// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Boards_Boards__Sz2v3 {
    display: flex;
    flex-direction: column;
    background-color: #c4e2e2;
    padding: 24px;
    align-items: center;
    font-size: 18px;
}

.Boards_boardTitle__MvsFJ {
    line-height: 16px;
    font-size: 12px;
    font-weight: 400;
}

.Boards_featureList__ndRiv {
    background-color: #f7f7f7;
    display: flex;
    text-align: left;
}

.Boards_boardInfo__schve{
    list-style-type: none;
    background-color: azure;
}

.Boards_tierList__dvn6O {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Boards_tierTitle__S-r73 {
    font-weight: 600;
    padding-left: 10px;
}

.Boards_tierDescription__v0M7f {
    display: flex;
    flex-direction: column;
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/Boards/Boards.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB","sourcesContent":[".Boards {\n    display: flex;\n    flex-direction: column;\n    background-color: #c4e2e2;\n    padding: 24px;\n    align-items: center;\n    font-size: 18px;\n}\n\n.boardTitle {\n    line-height: 16px;\n    font-size: 12px;\n    font-weight: 400;\n}\n\n.featureList {\n    background-color: #f7f7f7;\n    display: flex;\n    text-align: left;\n}\n\n.boardInfo{\n    list-style-type: none;\n    background-color: azure;\n}\n\n.tierList {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n}\n\n.tierTitle {\n    font-weight: 600;\n    padding-left: 10px;\n}\n\n.tierDescription {\n    display: flex;\n    flex-direction: column;\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Boards": `Boards_Boards__Sz2v3`,
	"boardTitle": `Boards_boardTitle__MvsFJ`,
	"featureList": `Boards_featureList__ndRiv`,
	"boardInfo": `Boards_boardInfo__schve`,
	"tierList": `Boards_tierList__dvn6O`,
	"tierTitle": `Boards_tierTitle__S-r73`,
	"tierDescription": `Boards_tierDescription__v0M7f`
};
export default ___CSS_LOADER_EXPORT___;
